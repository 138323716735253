// @import '~jsoneditor/dist/jsoneditor.min.css';
@import '@angular/material/theming';
@import './assets/themes/swag-theme.scss';

div.jsoneditor-tree {
	overflow: hidden;
}

/* You can add global styles to this file, and also import other style files */

/* DEBUGGER */
/* * {
  color:                    hsla(210, 100%, 100%, 0.9) !important;
  background:               hsla(210, 100%,  50%, 0.5) !important;
  outline:    solid 0.25rem hsla(210, 100%, 100%, 0.5) !important;
} */

router-outlet.router-flex + * {
	display: flex;
	flex: 1 1 auto;
	flex-direction: row;
}

router-outlet.router-container + swag-router {
	margin: -2rem;
}

swag-company {
	width: 100%;
}

button.current-page {
	background-color: mat-color($swag-accent, 500);
}

button.current-page-text {
	color: white;
}

body {margin:0;
	font-family: Muli, Verdana,'Segoe ui', Arial, Helvetica, sans-serif;
} 

.mat-tab-label {
    min-width: 20px;
}



.enabled {
	color: green;
	font-weight: bold;
}

.disabled {
	color: red;
	font-weight: bold;
}

.header {
	background-color: #d6e9e2;
	padding: 12px;
	color: #868FA2;
	font-weight: bold;
	font-style: italic;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 4px;
	margin: 10px;
	height: 50px;
	font-size: 15px;
}

.search {
	background-color: #FFF;
	border-color: #d6e9e2;
	border-width: 2px;
	border-style: solid;
	padding: 12px;
	color: #868FA2;
	font-weight: bold;
	font-style: italic;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 4px;
	margin: 10px;
	font-size: 12px;
	height: 50px;
}

.cart {
	background-color: #FFF;
	border-color: #d6e9e2;
	border-width: 2px;
	border-style: solid;
	padding: 12px;
	color: #868FA2;
	font-weight: bold;
	font-style: italic;
	text-transform: uppercase;
	text-align: center;
	letter-spacing: 4px;
	margin: 10px;
	font-size: 12px;
	height: 40px;
}

.loader {
	border: 16px solid #D4D4D4;
	border-radius: 50%;
	border-top: 16px solid #71C1A4;
	width: 120px;
	background-color: transparent;
	height: 120px;
	position: fixed;
	top:100px;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 99999999;
	-webkit-animation: spin 2s linear infinite;
	animation: spin 2s linear infinite;
  }
  @keyframes spin {
	  0% { transform: rotate(0deg); }
	  100% { transform: rotate(360deg); }
  }

.table-container {
	overflow: auto;
	width: 100%;
}

.print-table-container {
	border: solid 1px #707070;
	padding: 15px;
	border-radius: 5px;
	width: 900px;
}

.print-thanks-text {
	text-align: left;
	font-size: 13px;
	letter-spacing: 1px;
	color: #A7A7A7;
}

.print-delivery-text {
	text-align: left;
	font-size: 11px;
	letter-spacing: 1px;
	color: #A7A7A7;
}
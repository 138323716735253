@import '@angular/material/theming';
@include mat-core();

$swag-palette-primary: (
    100 : #d6e9e2,
    500 : #71C1A4,
    700 : #58c29b,
    contrast: (
        100 : $dark-primary-text,
        500 : $light-primary-text,
        700 : $light-primary-text,
    )
);

$swag-palette-accent: (
    100 : #d6e9e2,
    500 : #dc3939,
    700 : #791b1b,
    contrast: (
        100 : $dark-primary-text,
        500 : $light-primary-text,
        700 : $light-primary-text,
    )
);

$swag-primary: mat-palette($swag-palette-primary);
$swag-accent: mat-palette($swag-palette-accent);

$theme: mat-light-theme($swag-primary, $swag-accent);

$custom-typography: mat-typography-config(
    $font-family: 'Muli, Verdana, "Segoe ui", Roboto, sans-serif',
    $input: mat-typography-level(inherit, 2, 400),
    $body-1:  mat-typography-level(12px, 22px, 400),
    $body-2:  mat-typography-level(12px, 22px, 400),

);

@include angular-material-theme($theme);
@include angular-material-typography($custom-typography);

.mat-calendar-body-label,
.mat-calendar-period-button {
  color:#666 !important
}

.border-panel {
  border: solid 2px #71C1A4;
  border-radius: 10px;
  padding: 10px;
}

.menu
{
    font-weight: 100 !important;
    height: 40px !important;
    border: 1px white solid;
    text-align: center;
    margin-right: 20px;
    border-radius: 5px;
}

.menu-item
{
    font-size: 16px !important;
}

.menu-button
{
    background-color: white;
    color:#999 !important
}

// ToolTips
.tooltip-warning {
    background: #fa0;
}

.tooltip-green {
    background: #58c29b,;
}

.tooltip-red {
    background: #970000,;
}

.mat-row:hover {
    background-color: #d6e9e2;
    cursor: pointer;
}

// Tables
.mat-header-cell {
    font-size: 11px;
    font-weight: bold;
}

tr.mat-header-row {
    height: 55px !important;
}

tr.mat-row {
    height: 55px !important;
}

//TABS

.mat-tab-label
{
	background-color: #fff;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 1px;

    border: 1px solid #CCC !important;
    border-bottom: None;
}
.mat-tab-label-active
{
	background-color: #e3f5e6;
}

@keyframes spinner {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(180deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(360deg);
    }
  }

.spinning-logo{
    background-color: white;
    border-radius: 30px;
    border:1px solid white;
}

.spin{
    animation: 1.5s linear infinite spinner;
    animation-play-state: inherit;
}


.mat-dialog-title {
    text-align: left;
    font-size: 25px;
    font-weight: 600;
    letter-spacing: 1px;
    color: #636780
}

.section-title {
	text-align: left;
    font-size: 20px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #636780;
}

.subsection-title {
	text-align: left;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 1px;
    color: #636780;
}

.field-label {
	text-align: left;
    font-size: 14px;
    letter-spacing: 1px;
    color: #636780;
}

.mat-button{
    color: #FFF;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    letter-spacing: 1px;
}

.text-label {
	color: #118C7A;
	letter-spacing: 1px;
	font-weight: bold;
	text-align: left;
	padding: 5px;
	font-size: 14px;
}

.search-box
{
    padding: 5px !important;

    width: 300px !important;
    height: 21px !important;
    background-color: #F9F9F9 !important;
    border: 1px solid #71C1A4 !important;

    font-style: italic !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    color: #878787 !important;
}

.dropship-box
{
    padding: 5px !important;

    width: 50px !important;
    height: 21px !important;
    background-color: #F9F9F9 !important;
    border: 1px solid #AAA !important;

    font-style: italic !important;
    font-size: 12px !important;
    letter-spacing: 1px !important;
    color: #878787 !important;
}

.button-icon-action
{
    color:#000 !important;
    background-color: #fff !important;
    margin-left: 5px !important;
    border: 1px solid #AAA !important;
}


.action-icon
{margin-top: -8px;}

.mini-button {
    padding: 0px;
    line-height: 0px;
    min-width: 50px;
    height: 22px;
    font-size: 11px;
    border: 1px solid #71C1A4
}

.text-box-div {
  background-color: #F9F9F9;
  border: 1px #CCC solid;
  color: #666666;
  padding: 10px;
  font-size: 15px;
  border-color: #71C1A4;
}
